<template>
	<div class="home">
		主页
		
	</div>
</template>

<script>

	export default {
		name: 'Home',
		components: {
			// HelloWorld
		},
		computed: {
			// key() {
			// 	return this.$route.path
			// }
		},
		data() {
			return {
				nav: {

				},
				show: {
					showOne: '',
					showTwo: '',
					showThree: ''
				},
				tid:'',
				username:''
			}
		},
		created() {
			this.$router.push('/login')
			// this.tid = this.$local.get('tid')
			// this.username = this.$local.get('username')
			// if(this.tid&&this.username){
			// 	this.$router.push('/backReport')
			// }else{
			// 	this.$router.push('/login')
			// }
			
		},
		methods: {
			
		}
	}
</script>

<style lang="scss" scoped="scoped">
	
</style>
